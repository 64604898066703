import React from 'react';
import { Container, Card, Col, Row, Button } from 'react-bootstrap';
import styled from 'styled-components';
import LC from '../asset/LC.JPG';



//custom styles
 const Styles = styled.div`
  background-color: #1B1A1A;
 margin: 0rem;
 padding-top:0px;
 color: #F2F2F2;

 .text-box {
    text-align: left;

    padding-bottom:20px;
    background-color: #1B1A1A;
    color: #F2F2F2;
	};
   .h1 {
  text-align: center;
  color: #F2F2F2;
    }

   .icon-text {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    color: #F2F2F2;
	};

    .title{
    color: #2B6551;
    font-weight: bold;
    }

    .button{
      width: 100px;
      background-color:#2B6551 !important;
      border: none;
    }

    .text-skill{
      background-color: #2B6551;
      color: #F2F2F2;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 10px;
      border-radius: 25px;

    }

    .project{
      width: 30rem;
      background-color: #1B1A1A !important;
      color: #F2F2F2;
      /* padding: 1rem; */
      text-align: right;
      float: none;
   }
 `
const Skills =()=> {
    return (
        <Styles>
             <Container fluid>
             <br/>
                 <br/>
                 <br/>
                 <br/>
                 <h3 className="h1">Project Detail</h3>
                 <br/>
                 <br/>

                <Row>
                 <Col sm={6}>
                 <div >
                  <Card className="project float-right" id="1">
                   <Card.Img variant="top" src={LC} />
                     <Card.Body>
                      <span className='text-skill'>HTML</span>
                      <span className='text-skill'>CSS</span>
                      <span className='text-skill'>JavaScript</span>
                      <span className='text-skill'>Git</span>
                      <span className='text-skill'>Express</span>
                      <span className='text-skill'>NodeJS</span>
                      <span className='text-skill'>React</span>
                      <span className='text-skill'>Bootstrap</span>
                      <span className='text-skill'>MongoDB</span>

                      {/* <span className="badge rounded-pill bg-success">Success</span> */}
                        </Card.Body>
                       </Card>
                  </div>
                 </Col>

                 <Col sm={6}>
                 <div className='text-box'>
                  <h1 className='title'>* Archilist</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                 </div>
                 </Col>
                 </Row>

                 <div className="icon-text">
                   <Button href={`/projects`}  className="button btn m-4"> * Back </Button>
                 </div>
             </Container>
        </Styles>
     )
}

export default Skills
