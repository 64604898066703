import React, { Fragment, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import styled from 'styled-components';
import { FaAsterisk } from "react-icons/fa";

const P1 = styled.strong`
	font-size: 35px;
	font-weight: bold;
	margin-left: 2rem;
	color: #F2F2F2;
`;
const P2 = styled.strong`
	font-size: 24px;
	font-weight: bold;
	color: #F2F2F2;
	:hover{
		color: #2B6551;
	}

`;


const Header = () => {


	const [ colorChange, setColorChange] = useState(false);
	const changeNavbarColor = ()=>{
		if(window.scrollY > 10){
			setColorChange(true);
		}else{
			setColorChange(false);
		}
	}
	window.addEventListener("scroll", changeNavbarColor);

	return (
		<Fragment>
			{/* <Navbar bg={colorChange ? "dark":"transparent" }  fixed="top" collapseOnSelect expand="lg"> */}
			<Navbar  bg={colorChange ? "dark":"transparent"} fixed="top" variant="dark" collapseOnSelect expand="lg" >
				<Navbar.Brand href="/home" >
					<P1><FaAsterisk color="#2B6551"/> Ted Huang</P1>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="ml-auto">
						<Nav.Link href="/"> <P2>*home</P2>
						</Nav.Link>

						<Nav.Link href="/projects" >
							<P2>*projects</P2>
						</Nav.Link>

						<Nav.Link href="/skills" >
							<P2>*skills</P2>
						</Nav.Link>

						<Nav.Link href="/work" >
							<P2>*work</P2>
						</Nav.Link>

					</Nav>
				</Navbar.Collapse>
			</Navbar>
			{/* <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Container>
  <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
      <Nav.Link href="#features">Features</Nav.Link>
      <Nav.Link href="#pricing">Pricing</Nav.Link>


    </Nav>
    <Nav>
      <Nav.Link href="#deets">More deets</Nav.Link>
      <Nav.Link eventKey={2} href="#memes">
        Dank memes
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar> */}


		</Fragment>


	);
};

export default Header;
