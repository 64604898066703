import React from 'react';
import { Container, Card, CardGroup, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FaLinkedin } from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import { FaGithubSquare } from "react-icons/fa";


//import assets--Branding card images
import Avatar from'../asset/avatar.jpg';


//custom styles
 const Styles = styled.div`
 background-color: #1B1A1A;
 margin: 0rem;
 padding-top:0px;

 .avatar{
  border: 9px solid #2B6551;
  width: 300px;
  border-radius: 20px;
 }

 .card-body{
  color: #4e4e4e;
  font-size: 70px;
  font-weight: bold;
  padding: .5rem

 }

 .text-box {
  color: #F2F2F2;
    text-align: left;
    padding-bottom:20px;
	}

  .p{
    line-height: 1.8;
  }

  .cv-btn{
    background-color: #2B6551;
    border-color: #2B6551;
    :hover{
		color: #1f3f34;
	}
  }

  .social-media {
	color: #2B6551;
  margin-right:15px;
  /* margin-bottom: 1rem; */

	:hover{
		color: #1f3f34;
	}
}
 `

const Home =()=> {
    return (
        <Styles>
             <Container fluid>
               <br/>
               <br/>

               <br/>
               <br/>
               <br/>
               <br/>
               <br/>
               <br/>

             <Row>
            <Col>
            <CardGroup  >
            <Card className="bg-transparent border-0">
              <Card.Img variant="top" src={Avatar}  className="d-block mx-auto img-fluid avatar" />
              <Card.Body className="card-body">Ted</Card.Body>
              <Card.Body className="card-body">Tengbin</Card.Body>
              <Card.Body className="card-body">Huang</Card.Body>
            </Card>

            </CardGroup>
            </Col>

            <Col>
                  <div className='text-box'>
                  <h1>full-stack web developer | architect | photographer</h1>

                  <Button href='/' className="cv-btn mt-3 mb-4">Download CV</Button>
                   <div >

                    <h6 className="mt-3">
                   <AiFillMail className="social-media" size={"2rem"}/>
                   <a href="mailto:laoxianlx@gmail.com" className="text-box" >laoxianlx@gmail.com</a>
                   </h6>

                  <h6 className="mt-3">
                    <FaLinkedin className="social-media" size={"2rem"}/>
                    <a href="https:www.linkedin.com/in/ted-tengbin-huang-7a00485b/" className="text-box" >https:www.linkedin.com/in/ted-tengbin-huang-7a00485b/</a>

                  </h6>
                  <h6 className="mt-3">
                   <FaGithubSquare className="social-media" size={"2rem"}/>
                   <a href="https://github.com/TengbinHuang" className="text-box" >https://github.com/TengbinHuang</a>
                  </h6>
                   </div>
                   <br/>
                  <h6 className="p">Hi! My name is Ted Tengbin Huang. I had been working in the construction industry for seven years after graduating with a degree in architecture. Desire to change careers, my passion for problem-solving, strong interests of coding and solid design background lead me down the path of web development.</h6>

                  <h6 className="p">I am currently a full-time student doing my Diploma of Information Technology in Holmesglen Institute. I aim to join a firm that is committed to building an inclusive culture that supports a diverse workforce, helping me to gain experiences that will set me up for a lifetime in the industry. With my knowledge, skills and prior work experience, I respond well to challenges in an agile and adaptable team environment.
                  </h6>
                 </div>
            </Col>
            </Row>
             </Container>
        </Styles>
     )
}

export default Home
