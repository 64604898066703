import React from 'react';
import { Container, Row } from 'react-bootstrap';
import {Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaFacebook,  FaLinkedin,FaGithubSquare } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import {BsTwitter} from "react-icons/bs";

const FooterContainer = styled.div`
   background-color: #1B1A1A;

	padding-top: 1rem;
	border-top: 2px solid #2B6551;

	font-size: 12px;
	color: #F2F2F2;

	.social-media {
	border-color: #2B6551;
	color: #2B6551;
	:hover{
		color: #1f3f34;

	}
	}

`;

const Footer = () => {

	const getCurrentYear = () => {
		return new Date().getFullYear();
	};
	return (
		<FooterContainer>
			<Container className="text-center">
			<link rel="stylesheet" type="text/css" />

				<Row className="footer-bottom justify-content-center">
               <div>
				<br />
				<br />

				<div >
			    <Link to="/" className="social-media m-2"><FaFacebook size={"2rem"} /></Link>
				<Link to="/" className="social-media  m-2"><FaLinkedin size={"2rem"} /></Link>
                <Link to="/" className="social-media  m-2"><FaGithubSquare size={"2rem"} /></Link>
                <Link to="/" className="social-media  m-2"><GrInstagram size={"2rem"} /></Link>
				<Link to="/" className="social-media  m-2"><BsTwitter size={"2rem"} /></Link>

			    </div>

				<p className="pr-6 m-2">   &copy; {getCurrentYear()} Built by Ted Huang</p>
				</div>
				</Row>
			</Container>
		</FooterContainer>

	);
};

export default Footer;