import React from 'react';
import { Container, Image } from 'react-bootstrap';
import styled from 'styled-components';
import HTML from '../asset/skills/HTML.svg';

const H2 = styled.h2`
	font-size: 3rem;
	font-weight: bold;
	padding-bottom: 1rem;
	text-align: center;
`;

const H3 = styled.h3`
  font-size: 1.1rem;
  text-align: center;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 6rem;
`;

const NavbarBox = styled.div`
	height: 4.3rem;
`;

const Styles = styled.div`
background-color: #1B1A1A;
color: #F2F2F2;
  .container {
    padding-bottom: 1rem;
    margin-top: 2rem;
  }
`


const NotFound = () => {
	return (
		<Styles>
			<NavbarBox></NavbarBox>
			<Container id="main">
				<br/>


				<div><Image src={HTML} className="rounded mx-auto d-block" style={{ width:'300px' }}/></div>
				<br/>
				<br/>

				<H2>{' '}PAGE NOT FOUND</H2>
				<H3>It seems that there is an error somewhere</H3>
				<ButtonContainer>
					<a className="btn btn-success" src={HTML} href="/">
						Return Home
					</a>
				</ButtonContainer>
				<br/>
				<br/>
				<br/>

			</Container>
		</Styles>
	);
};

export default NotFound;
