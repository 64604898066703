import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';



//custom styles
const Styles = styled.div`
  background-color: #1B1A1A;
  margin: 0rem;
  padding-top:0px;

  .text-box {
   color: #F2F2F2;
     text-align: left;
     padding-bottom:20px;
     }

  .h1 {
  text-align: center;
  color: #F2F2F2;
    }
  .title{
    color: #2B6551;
    font-weight: bold;
    }

    /* div.scroll {
                margin:4px, 4px;
                padding:4px;
                width: 500px;
                height: 1000px
                overflow-x: hidden;
                overflow-y: auto;
    } */

`

const Work =()=> {
    return (
        <Styles>
             <Container fluid>
                 <br/>
                 <br/>
                 <br/>
                 <br/>

                 <h1 className='h1'>work experience</h1>
                 <br/>
                 <br/>

                 <Row>
                 <Col sm={3} >
                  <div>
                    {/* to be updated for scrollbar */}
                  {/* <img src={Scrollbar} className="mx-auto img-fluid mr-1" style={{ width:'200px'}}/> */}
                  </div>
                 </Col>

                 <Col sm={9}>
                   <div className='text-box'>
                  <h3 className='title'>National Australia Bank</h3>
                  <p>Cloud Engineer intern  |   Jan. 2022 - NOW</p>
                  <p>●	Use AWS and Azure cloud technology to migrate company legacy from local data center </p>
                  <p>●	Get training from MS team for Azure Administration certification </p>
                  <p>●  Participate the daily standup team meeting</p>
                  <p>●	Use Vue.js and dot NET core technology for company projects</p>
                 </div>

                  <div className='text-box'>

                  <h3 className='title'>ClarkeHopkinsClarke </h3>
                  <p>Experienced Architectural Graduate  |   Jan. 2017 -  Arpil 2020</p>
                  <p>●	Provide technical suggestion, support for company database interface (File-maker)</p>
                  <p>●	Technical support and training for Building Information Modelling (BIM) in Revit</p>
                  <p>●	Coordination and collaboration projects with consultants (Engineers, surveyors)</p>
                  <p>●	Contract administration with builders and project management</p>
                 </div>

                 <div className='text-box'>

                  <h3 className='title'>Baenziger coles</h3>
                  <p>Architectural Graduate  |   August 2014 -  April 2017</p>
                  <p>●	Provide technical suggestion, support for company database interface (File-maker)</p>
                  <p>●	Technical support and training for Building Information Modelling (BIM) in Revit</p>
                  <p>●	Coordination and collaboration projects with consultants (Engineers, surveyors)</p>
                  <p>●	Contract administration with builders and project management</p>
                 </div>

                 </Col>
                 </Row>

             </Container>
        </Styles>
     )
}

export default Work
