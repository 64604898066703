import React from 'react';
import { Container, Card,  Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Azure from '../asset/skills/Azure-Light.svg';
import AWS from '../asset/skills/AWS-Light.svg';
import Bootstrap from '../asset/skills/Bootstrap.svg';
import CSS from '../asset/skills/CSS.svg';
import Express from '../asset/skills/ExpressJS-Light.svg';
import Git from '../asset/skills/Git.svg';
import MySQL from '../asset/skills/MySQL-Light.svg';
import NodeJS from '../asset/skills/NodeJS-Light.svg';
import Python from '../asset/skills/Python-Light.svg';
import ReactIcon from '../asset/skills/React-Light.svg';
import Figma from '../asset/skills/Figma-Light.svg';
import Photoshop from '../asset/skills/Photoshop.svg';
import Illustrator from '../asset/skills/Illustrator.svg';
import HTML from '../asset/skills/HTML.svg';
import AutoCad from '../asset/skills/AutoCAD-Light.svg';
import Sketch from '../asset/skills/Sketchup.svg';
import Revit from '../asset/skills/Revit.svg';




//custom styles
 const Styles = styled.div`
 margin: 0rem;
 padding-top:0px;

 .page-title{
    text-align: center;
 }

 .text-box {
    text-align: left;

    padding-bottom:20px;
    background-color: #1B1A1A;
    color: #F2F2F2;
	};

   .h1 {
  text-align: center;
  color: #F2F2F2;
    }

   .icon-text {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    color: #F2F2F2;
	};

    .title{
    color: #2B6551;
    font-weight: bold;
    }

    .hr {
     margin-bottom:30px;
     background-color:#2B6551;
     padding: 1px;
     width: 50%;


}
 `
const Skills =()=> {
    return (
        <Styles>
             <Container fluid  className="text-box">
                 <br/>
                 <br/>
                 <br/>
                 <br/>
                 <h3 className="h1">work skills</h3>
                 <br/>
                 <br/>

                  <Row>
                 <Col sm={6}>
                  {/* <CardGroup variant="top" className="icon-text" style={{ width:'120px' }}>
                  <Card className="bg-transparent border-0">

                  <Card.Img src={Azure} className="mx-auto img-fluid" style={{ width:'80px' }} />
                  <Card.Body>
                  <Card.Title>Azure</Card.Title>
                  </Card.Body>
                  </Card>
                 </CardGroup> */}

                 <div className="d-flex flex-wrap bd-highlight example-parent m-2" >
                  <div className="icon-text"><Card.Img src={Azure} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>Azure</Card.Title></div>

                  <div className="icon-text"><Card.Img src={AWS} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>AWS</Card.Title></div>

                  <div className="icon-text"><Card.Img src={HTML} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>HTML</Card.Title></div>

                  <div className="icon-text"><Card.Img src={Bootstrap} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title style={{ width:'80px' }}>Bootstrap</Card.Title></div>

                  <div className="icon-text"><Card.Img src={CSS} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>CSS</Card.Title></div>

                  <div className="icon-text"><Card.Img src={Express} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>Express</Card.Title></div>

                  <div className="icon-text"><Card.Img src={Git} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>Git</Card.Title></div>

                  <div className="icon-text"><Card.Img src={MySQL} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>MySQL</Card.Title></div>

                  <div className="icon-text"><Card.Img src={NodeJS} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>NodeJS</Card.Title></div>

                  <div className="icon-text"><Card.Img src={Python} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>Python</Card.Title></div>
                  <div className="icon-text"><Card.Img src={ReactIcon} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>React</Card.Title></div>
                  <div className="icon-text"><Card.Img src={Figma} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>Figma</Card.Title></div>

                  </div>

                 </Col>

                 <Col sm={6}>
                 <div className='text-box'>

                  <h3 className='title'>Web Developer</h3>

                  <p>A web developer makes and maintains websites. They are in charge of a site’s overall look and feel. Web developers also handle the technical aspects of a website, including its performance (website speed) and capacity (the maximum amount of traffic the site could handle at a given time). A web developer is usually knowledgeable in both graphic design and programming.
                  </p>

                  <p>
                  Web developers deal with different kinds of websites, including e-commerce, gaming, and news sites. These different website types have certain requirements. For instance, a gaming website must deftly handle advanced graphics. Ecommerce sites, on the other hand, would require the integration of a payment-processing feature and the capacity to deal with stock coming in and out. A web developer decides a site’s applications and designs accordingly.

                  </p>
                 </div>

                 </Col>
                 </Row>

                 <br/>
                 <br/>
                 <div className="hr"/>

                 <Row>
                 <Col sm={6}>
                 <div className="d-flex flex-wrap bd-highlight example-parent m-2" >
                  <div className="icon-text"><Card.Img src={Illustrator} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title style={{ width:'80px' }}>Illustrator</Card.Title></div>

                  <div className="icon-text"><Card.Img src={AutoCad} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>AutoCad</Card.Title></div>

                  <div className="icon-text "><Card.Img src={Photoshop} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title style={{ width:'80px' }}>Photoshop</Card.Title></div>

                  <div className="icon-text "><Card.Img src={Figma} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>Figma</Card.Title></div>

                  <div className="icon-text "><Card.Img src={Sketch} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>Sketch</Card.Title></div>

                  <div className="icon-text "><Card.Img src={Revit} className="mx-auto img-fluid" style={{ width:'80px' }}/><Card.Title>Revit</Card.Title></div>
                  </div>

                  </Col>

                   <Col sm={6}>
                  <div className='text-box'>
                  <h3 className='title'>Architect</h3>
                  <p>Architects’ duties vary depending on the type of firm they work for, where they live and some other factors. They may be involved in all phases of a construction project, from explaining ideas to clients and contractors, to drawing plans and specifications, to overseeing the different construction phases. Architects often collaborate with experts in related fields, such as interior design, city planning and structural engineering.
                </p>
                 </div>
                    </Col>
                 </Row>

             </Container>
        </Styles>
     )
}

export default Skills
