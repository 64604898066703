import React from 'react';
import { Container, Card, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Spork from '../asset/Spork.jpg';
import Archilist from '../asset/Archilist.jpg';
import LC from '../asset/LC.JPG';


//custom styles
 const Styles = styled.div`
  background-color: #1B1A1A;
 margin: 0rem;
 padding-top:0px;
 color: #F2F2F2;

 .text-box {
    text-align: left;

    padding-bottom:20px;
    background-color: #1B1A1A;
    color: #F2F2F2;
	};

   .h1 {
  text-align: center;
  color: #F2F2F2;
    }

    .title{
    color: #2B6551;
    font-size: 46px ;
    font-weight: bold;
    }

   .project{
      width: 38rem;
      background-color: #1B1A1A !important;
      color: #F2F2F2;
      /* margin: 30px; */
      padding-top: 2rem;
      padding-left: 4rem;
      padding-right: 4rem;
      text-align: left;
      float: none;
   }
 `
const ProjectDetails =()=> {

    return (
        <Styles>
             <Container fluid >
                 <br/>
                 <br/>
                 <br/>
                 <br/>
                 <h3 className='h1'>Projects</h3>


                 <div className='text-box row row-cols-1 row-cols-md-1 g-3'>

                 <Row xs={1} md={2} className="project mx-auto">

               <Card className="project" id="1">
                     <a href="/project/1">
                       <Card.Img variant="top" src={LC} />
                     </a>
                     <Card.Body>
                      <Card.Title className="title">Leverage-Capital</Card.Title>
                       <Card.Text>
                          financial management website
                        </Card.Text>
                        </Card.Body>
                       </Card>

                       <Card className="project" id="2">
                       <a href="/project/1">
                       <Card.Img variant="top" src={Spork}/>
                     </a>
                     <Card.Body>
                      <Card.Title className="title">Spork’d</Card.Title>
                       <Card.Text>
                        restaurants information and rating website
                        </Card.Text>
                        </Card.Body>
                       </Card>

                       <Card className="project m-2" id="3">
                      <a href="/project/1">
                        <Card.Img variant="top" src={Archilist} />
                      </a>
                     <Card.Body>
                      <Card.Title className="title">Archilist</Card.Title>
                       <Card.Text>
                        Award melbourne buildings website
                        </Card.Text>
                        </Card.Body>
                       </Card>

                    </Row>
                 </div>
             </Container>
        </Styles>
     )
}

export default ProjectDetails
