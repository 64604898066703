import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Header from './components/Header'
import Footer from './components/Footer'
import Skills from './Pages/Skills'
import Work from './Pages/Work'
import Projects from './Pages/Projects'
import ProjectDetails from './Pages/ProjectsDetails'

import NotFound from './Pages/NotFound'
import Home from './Pages/Home.jsx';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (

    <Router >
      <div className = "app-layout" >
      <Header> </Header>

      <Routes>
      <Route exact path ='/' element={<Home/>} />

      <Route exact path = '/projects' element = {<Projects/>}/>

      <Route exact path = '/project/:id' element = {<ProjectDetails/>} />

      <Route exact path = '/skills' element = {<Skills/>} />

      <Route exact path = '/work' element = {<Work/>} />

      <Route path='*' element={<NotFound/>}> </Route>

      </Routes>
        {/* <Layout /> */}
        <Footer />
      </div>
    </Router>
          )
}

export default App;